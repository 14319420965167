import revive_payload_client_rNzQbov1mp from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.1_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_YgCOTt0wfL from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.1_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QuFByJYKcj from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.1_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_5TGapJwmPR from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt-site-config@2.2.17_vite@5.4.5_vue@3.5.4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_etcvgMKn5p from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.1_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_W8ww0QcRPH from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.1_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_wHPBmJXqoH from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.1_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_fQBVvylede from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.1_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_NLABEGZAwj from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.4_typescript@5.6.2_vue@3.5.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_ZxtKt6rnGj from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.13.1_eslint@8.57.0_sass@1.78.0_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_1KHwYEi6s4 from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt-graphql-client@0.2.36_graphql-tag@2.12.6_typescript@5.6.2/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import datadog_client_cfAAU11B0P from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/datadog.client.ts";
import ks_toast_client_iF5eIzTVpP from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/ks-toast.client.ts";
import error_handler_kEP5FliEXj from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/error-handler.ts";
import vue_masonry_wall_13R6vu58Yn from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/plugins/vue-masonry-wall.ts";
export default [
  revive_payload_client_rNzQbov1mp,
  unhead_YgCOTt0wfL,
  router_QuFByJYKcj,
  _0_siteConfig_5TGapJwmPR,
  payload_client_etcvgMKn5p,
  navigation_repaint_client_W8ww0QcRPH,
  check_outdated_build_client_wHPBmJXqoH,
  chunk_reload_client_fQBVvylede,
  plugin_vue3_NLABEGZAwj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZxtKt6rnGj,
  plugin_1KHwYEi6s4,
  datadog_client_cfAAU11B0P,
  ks_toast_client_iF5eIzTVpP,
  error_handler_kEP5FliEXj,
  vue_masonry_wall_13R6vu58Yn
]