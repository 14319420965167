import type { ProductVariant } from "~/types/product-catalogue";
import type { CartItemDto } from "~/types/core/ecommerce/cart";

export function createSimpleCartItemMapper(totStudents: number) {
  return <TVariant extends string>(
    item: CartItemDto,
    variant: ProductVariant<TVariant> | undefined,
  ): SimpleCartItem => {
    // TODO: Verify that variant subscription plan is correct and matches the cart item
    return {
      id: item.uuid,
      isbn: item.productId ?? variant?.isbn,
      amount: item.amount,
      isTrial: false,
      name: item.productName ?? variant?.title,
      variant,
      prices: variant && calculatePrices(variant, totStudents),
      fixedAmount: variant?.fixedAmount ?? false,
      type: isSingleProductVariant(variant)
        ? "Digital bok"
        : "Digitalt læremiddel",
    };
  };
}
